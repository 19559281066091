<template>
  <div class="create-contact-inputs">
    <div class="create-contact-inputs__contact">
      <RCInput
        :label="$t('stopsInfo.createStopInfo.firstName')"
        :class="emptyFields['first_name'] === true ? 'missing-field' : ''"
        :floatLabel="true"
        class="create-contact-inputs__input-field"
        type="text"
        v-model="newContactInfo.first_name"
        :minlength="inputLimits.first_name.min"
        :maxlength="inputLimits.first_name.max"
      />
      <RCInput
        :label="$t('stopsInfo.createStopInfo.lastName')"
        :class="emptyFields['last_name'] === true ? 'missing-field' : ''"
        :floatLabel="true"
        class="create-contact-inputs__input-field"
        type="text"
        v-model="newContactInfo.last_name"
        :minlength="inputLimits.last_name.min"
        :maxlength="inputLimits.last_name.max"
      />
      <div class="create-contact-inputs__phone-number-content">
        <InputPhone
          @changePhone="handlePhoneNumber"
          @changeFlag="handleSelectedFlag"
          :phoneValue="newContactInfo.phone"
          :emptyFields="emptyFields"
        ></InputPhone>
      </div>
      <RCInput
        :label="$t('stopsInfo.createStopInfo.email')"
        :class="emptyFields['email'] === true ? 'missing-field' : ''"
        :floatLabel="true"
        class="create-contact-inputs__input-field"
        type="text"
        v-model="newContactInfo.email"
        :minlength="inputLimits.email.min"
        :maxlength="inputLimits.email.max"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import ValidateEmptyFields from "../../../../utils/validateEmptyFields";
import InputPhone from "../../../../components/InputPhone.vue";

export default {
  /* eslint-disable no-return-assign */
  name: "CreateContactInputs",
  props: { newContactInfo: Object },
  components: {
    RCInput,
    InputPhone,
  },
  data() {
    return {
      selectedFlag: "",
      selectedContact: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      emptyFields: {
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
      },
      error: {
        phoneNumber: false,
      },
      inputLimits: {
        first_name: {
          min: 1,
          max: 40,
        },
        last_name: {
          min: 1,
          max: 40,
        },
        phone: {
          min: 7,
          max: 25,
        },
        email: {
          min: 7,
          max: 100,
        },
      },
    };
  },
  watch: {
    "selectedContact.first_name": {
      handler(newValue) {
        const result = this.replaceNumbersForLetters(newValue);
        Vue.nextTick(() => (this.selectedContact.first_name = result));
      },
    },
    "selectedContact.last_name": {
      handler(newValue) {
        const result = this.replaceNumbersForLetters(newValue);
        Vue.nextTick(() => (this.selectedContact.last_name = result));
      },
    },
  },
  created() {
    this.selectedContact = this.newContactInfo;
  },
  methods: {
    handlePhoneNumber(newPhoneNumber) {
      this.newContactInfo.phone = newPhoneNumber;
    },
    handleSelectedFlag(newFlag) {
      if (newFlag !== "") {
        this.selectedFlag = newFlag;
      }
    },
    replaceNumbersForLetters(value) {
      const result = value.toString().replace(/\d/g, "");
      return result;
    },
    validateFormfields() {
      const validatedForm = ValidateEmptyFields(this.selectedContact);
      this.resetEmptyFieldsValues();
      validatedForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      if (validatedForm.validation) {
        this.addPrefixPhoneNumber();
        this.$emit("input", this.selectedContact);
      }
      if (validatedForm.validation) {
        return validatedForm.validation;
      }
      return false;
    },
    addPrefixPhoneNumber() {
      let newCountryCode = "";
      switch (this.selectedFlag) {
        case "MEX":
          newCountryCode = "52";
          break;
        case "USA":
        case "CAN":
          newCountryCode = "1";
          break;
        default:
          newCountryCode = "1";
      }

      // Regular expression to find the prefix of the above country code.
      const regex = new RegExp(`^${this.countryCode}`);

      // Remove the previous country code from the phone number
      const currentPhone = this.selectedContact.phone.replace(regex, "");

      // Add new country code
      this.selectedContact.phone = newCountryCode + currentPhone;

      // Update the current country code
      this.countryCode = newCountryCode;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
    validatePhoneNumber(number) {
      let response = "";
      const firstCharacter = this.selectedContact.phone.charAt(0);
      // eslint-disable-next-line eqeqeq
      if (firstCharacter == 1) {
        if (this.selectedContact.phone) {
          if (number.length === 11) {
            response = true;
          } else {
            response = false;
          }
        }
      } else if (this.selectedContact.phone) {
        if (number.length === 10) {
          response = true;
        } else {
          response = false;
        }
        return response;
      }
      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";

.create-contact-inputs {
  display: flex;
  flex-direction: column;
  &__contact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    column-gap: 3px;
  }
  &__phone-number-content {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    border: 1px solid $color-border-container !important;
    margin: 16px;
  }
  &__phone {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: left;
    // width: 100%;
    // margin: 5% 0 0 11%;
    @media (max-width: 991px) {
      // margin: 5% 0px;
    }
    @media (max-width: 500px) {
      // margin: 0px;
    }
  }
  &__phone-validation {
    position: absolute;
    margin-top: 40px;
    color: red;
    font-size: 0.8rem;
    @media (max-width: 500px) {
      margin-top: 50px;
    }
  }
  &__input-field {
    margin: 5% 2%;
    width: 90%;
    @media (max-width: 500px) {
      width: 96%;
    }
  }
}

.rule-of-number {
  position: absolute;
  color: red;
  font-size: 0.8rem;
  margin-left: 7px;
}
.phone-number-content {
  width: 99%;
  position: relative;
}

@media (max-width: 500px) {
  .create-contact-inputs {
    &__contact {
      display: flex;
      flex-direction: column;
    }
    .input-container {
      max-width: none;
      margin: 4% 2%;
    }
  }
  .rule-of-number {
    top: 58px;
  }
}

::v-deep {
  .el-select {
    width: 90px;
  }
  .el-input__inner {
    border: none !important;
    border-radius: 5px !important;
  }
}
</style>
