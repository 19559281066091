<template>
  <div class="create-account-inputs">
    <div class="create-account-inputs__account">
      <RCInput
        class="create-account-inputs__input-field"
        :class="emptyFields['name'] === true ? 'missing-field' : ''"
        :label="$t('stopsInfo.createStopInfo.name')"
        :floatLabel="true"
        :type="'text'"
        :requiredField="true"
        v-model="newAccountInfo.name"
        :minlength="inputLimits.name.min"
        :maxlength="inputLimits.name.max"
      />
      <RCInput
        class="create-account-inputs__input-field"
        :class="emptyFields['street'] === true ? 'missing-field' : ''"
        :label="$t('stopsInfo.createStopInfo.street')"
        :type="'text'"
        :floatLabel="true"
        :requiredField="true"
        v-model="newAccountInfo.street"
        :minlength="inputLimits.street.min"
        :maxlength="inputLimits.street.max"
      />
      <!-- NEW SELECT AUTO COMPLETE -->
      <AutoComplete
        :label="$t('stopsInfo.createStopInfo.city')"
        :suggestions="filteredCity"
        :requiredField="true"
        :missingField="emptyFields['city'] === true"
        :showPrefixIcon="false"
        @search="searchWord($event, 'city')"
        @selectOption="selectWord($event)"
        @blur="updateWord()"
        v-model="newAccountInfo.city"
      />
      <!-- END NEW SELECT AUTO COMPLETE -->
      <RCInput
        class="create-account-inputs__input-field create-account-inputs__input-field--state-code"
        :class="emptyFields['state_code'] === true ? 'missing-field' : ''"
        type="text"
        :label="$t('stopsInfo.createStopInfo.stateCode')"
        :floatLabel="true"
        :disabled="true"
        v-model="newAccountInfo.state_code"
        :minlength="inputLimits.zip_code.min"
        :maxlength="inputLimits.zip_code.max"
        :key="keyState"
      />
      <!-- NEW SELECT AUTO COMPLETE -->
       <AutoComplete
        :label="$t('stopsInfo.createStopInfo.zipCode')"
        :suggestions="filteredZipCode"
        :requiredField="true"
        :missingField="emptyFields['zip_code'] === true"
        :showPrefixIcon="false"
        @search="searchWord($event, 'zipCode')"
        @selectOption="selectWord($event)"
        @blur="updateWord()"
        v-model="newAccountInfo.zip_code"
       />
      <!-- END NEW SELECT AUTO COMPLETE -->
      <RCSelect
        class="create-account-inputs__input-field"
        :class="emptyFields['country_code'] === true ? 'missing-field' : ''"
        :suggestions="countryCodes"
        :floatLabel="true"
        :label="$t('stopsInfo.createStopInfo.countryCode')"
        v-model="newAccountInfo.country_code"
      />
    </div>
    <AlertStreetValidations v-if="streetHaveSymbols" class="alert-error" />
  </div>
</template>

<script>
import RestrictionsFTLAndExpedited from "@/Extend/RestrictionsFTLAndExpedited";
import AlertStreetValidations from "@/components/AlertStreetValidations.vue";
import AutoComplete from '@/components/AutoComplete.vue';
import LocationSchema from "../../../../schemas/NewLocationSchema";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import RCSelect from "../../../../components/RCComponents/CustomRCSelect.vue";

export default {
  name: "CreateAccountInputs",
  props: { newAccountInfo: Object, zipcode: String },
  mixins: [RestrictionsFTLAndExpedited],
  components: {
    RCInput,
    RCSelect,
    AlertStreetValidations,
    AutoComplete,
  },
  data() {
    return {
      filteredCity: [],
      filteredZipCode: [],
      selectedLocation: {
        country_code: "US",
        city: "",
        state_code: "",
        zip_code: "",
        street: "",
        name: "",
        createLocationInBd: true,
      },
      emptyFields: {
        country_code: false,
        city: false,
        state_code: false,
        zip_code: false,
        name: false,
        street: false,
      },
      countryCodes: [
        { label: "US", value: "US" },
        { label: "CA", value: "CA" },
        { label: "MX", value: "MX" },
      ],
      keyState: 0,
      tokenInfo: null,
      streetHaveSymbols: false,
      inputLimits: {
        name: {
          min: 1,
          max: 23
        },
        street: {
          min: 1,
          max: 100
        },
        city: {
          min: 2,
          max: 25
        },
        zip_code: {
          min: 5,
          max: 10,
        }
      }
    };
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.selectedLocation = {
      city: this.newAccountInfo.city,
      country_code: this.newAccountInfo.country_code,
      state_code: this.newAccountInfo.state_code,
      zip_code: this.newAccountInfo.zip_code,
      name: this.newAccountInfo.name,
      street: this.newAccountInfo.street,
    };
    if (this.zipcode) {
      this.selectedLocation.zip_code = this.zipcode;
    }
  },
  watch: {
    newAccountInfo: {
      deep: true,
      handler() {
        this.selectedLocation = {
          city: this.newAccountInfo.city,
          country_code: this.newAccountInfo.country_code,
          state_code: this.newAccountInfo.state_code,
          zip_code: this.newAccountInfo.zip_code,
          name: this.newAccountInfo.name,
          street: this.newAccountInfo.street,
        };
      },
    },
    "newAccountInfo.name": {
      handler(newValue) {
        if (newValue && newValue.length > 25) {
          this.newAccountInfo.name = newValue.substring(0, 25);
        }
      },
    },
    "newAccountInfo.street": {
      handler(newValue) {
        const alphanumeric = /^[a-zA-Z0-9\s]*$/;
        if (!alphanumeric.test(newValue)) {
          this.streetHaveSymbols = true;
          this.emptyFields.street = true;
          return;
        }
        this.streetHaveSymbols = false;
        this.emptyFields.street = false;
      },
    },
    "$route.params": {
      immediate: true,
      handler() {
        if (
          this.$route.params.quoteType === "ftl" ||
          this.$route.params.quoteType === "expedited"
        ) {
          this.denyCanadaCountryInFtlAndExpeditedLoad();
        }
      },
    },
  },
  methods: {
    async searchWord(event, type) {
      if (!event.query.trim().length || event.query.length < 2) {
        switch (type) {
          case "city":
            this.filteredCity = [];
            break;
          case "zipCode":
            this.filteredZipCode = [];
            break;
          default:
            break;
        }
      } else {
        let response;
        let data = {};
        switch (type) {
          case "city":
            data = {
              params: {
                city: event.query,
              },
            };
            response = await this.$store.dispatch("load/stops/getAllLocations", data);
            this.filteredCity = [
              {
                options: response.map((item) => ({
                  ...item,
                  label: `${item.city}, ${item.state_code}, ${item.zip_code}`,
                })),
              },
            ];
            break;
          case "zipCode":
            data = {
              params: {
                zip_code: event.query,
                country_code: this.newAccountInfo.country_code,
              },
            };
            response = await this.$store.dispatch("load/stops/getAllLocations", data);
            this.filteredZipCode = [
              {
                options: response.map((item) => ({
                  ...item,
                  label: `${item.city}, ${item.state_code}, ${item.zip_code}`,
                })),
              },
            ];
            break;
          default:
            break;
        }
      }
    },
    async selectWord(location) {
      this.newAccountInfo.city = location.city;
      this.newAccountInfo.state_code = location.state_code;
      this.newAccountInfo.zip_code = location.zip_code;
      this.selectedLocation = location;
      // this.keyState += 1;
    },
    updateWord() {
      if (
        // eslint-disable-next-line operator-linebreak
        this.newAccountInfo.city !== this.selectedLocation.city ||
        // eslint-disable-next-line operator-linebreak
        this.newAccountInfo.city === "" ||
        // eslint-disable-next-line operator-linebreak
        this.newAccountInfo.zip_code !== this.selectedLocation.zip_code ||
        this.newAccountInfo.zip_code === ""
      ) {
        this.newAccountInfo.city = "";
        this.newAccountInfo.state_code = "";
        this.newAccountInfo.zip_code = "";
        this.$emit("updateNewAccountInfo", this.newAccountInfo);
        this.keyState += 1;
      }
    },
    async validateFormfields() {
      try {
        Object.keys(this.emptyFields).forEach((key) => {
          this.emptyFields[key] = false;
        });
        const isValid = await LocationSchema.validate(this.newAccountInfo, {
          abortEarly: false,
        });
        if (this.streetHaveSymbols) {
          this.emptyFields.street = true;
          return false;
        }
        return isValid;
      } catch (error) {
        error.inner.forEach((element) => {
          this.emptyFields[element.path] = true;
        });
        return false;
      }
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";

.alert-error {
  width: 95%;
  margin: auto;
}

.create-account-inputs {
  display: flex;
  flex-direction: column;
  &__account {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-items: center;
    column-gap: 3px;
  }
  &__input-field {
    margin: 5% 2%;
    width: 90%;
    @media (max-width: 500px) {
      width: 96%;
    }
    &--state-code {
      cursor: no-drop;
    }
  }
}

.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  color: $color-primary-company;
  border-radius: 15px;
}
@media (max-width: 500px) {
  .create-account-inputs {
    &__account {
      display: flex;
      flex-direction: column;
    }
  }
}

::v-deep {
  .autocomplete {
    width: 90%;
    margin: 5%;
  }
}
</style>
